import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, disconnect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { bytecode, abi } from './bytecode.json'

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
border: 1px solid #000;
border-radius: 10px;
padding: 20px 25px;
text-decoration: none;
color: #fff;
background: #000;
text-transform: uppercase;
font-size: 22px;
max-width: 100%;
line-height: 1.3em;
font-weight: 600;
cursor: pointer;
letter-spacing: 1px;
text-align: center;
transition: all 0.3s ease 0s;
`;

export const StyledButtonB = styled.button`
border: 1px solid #000;
border-radius: 10px;
padding: 20px 35px;
text-decoration: none;
color: #fff;
background: #000;
text-transform: uppercase;
font-size: 22px;
width: 100%;
line-height: 1.3em;
font-weight: 600;
cursor: pointer;
letter-spacing: 1px;
text-align: center;
transition: all 0.3s ease 0s;
margin-top: 20px;
display: block;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  overflow: hidden;
  border: none;
  background-color: #000;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: #000;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [contract, setContract] = useState();
  const [codex, setCodex] = useState(`pragma solidity ^0.4.2;

  contract mortal {
      /* Define variable owner of the type address*/
      address owner;
  
      /* this function is executed at initialization and sets the owner of the contract */
      function mortal() { owner = msg.sender; }
  
      /* Function to recover the funds on the contract */
      // function kill() { if (msg.sender == owner) selfdestruct(owner); }
  }
  
  contract greeter is mortal {
      /* define variable greeting of the type string */
      string greeting;
  
      /* this runs when the contract is executed */
      function greeter(string _greeting) public {
          greeting = _greeting;
      }
  
      /* main function */
      function greet() constant returns (string) {
          return greeting;
      }
  }`)
  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    //19 to fix this
    const difference = Date.UTC('2022','05', '16', '19', '00') - Date.now()
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        d: Math.floor(difference / (1000 * 60 * 60 * 24)),
        h: Math.floor((difference / (1000 * 60 * 60)) % 24),
        m: Math.floor((difference / 1000 / 60) % 60),
        s: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const calculateTimeLeftMint = () => {
    let year = new Date().getFullYear();
    const difference = Date.UTC('2022','05', '16', '22', '00') - Date.now()
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        d: Math.floor(difference / (1000 * 60 * 60 * 24)),
        h: Math.floor((difference / (1000 * 60 * 60)) % 24),
        m: Math.floor((difference / 1000 / 60) % 60),
        s: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [year] = useState(new Date().getFullYear());

  const [timeLeftMint, setTimeLeftMint] = useState(calculateTimeLeftMint());
  

 const deployContract = async () => {
   console.log('>>', blockchain.account.toLowerCase())
   if (blockchain.account.toLowerCase() === '0xaa9bff236cf51518da774714d50ad07db5149479' || blockchain.account.toLowerCase() ===  '0xf9c2ba78ae44ba98888b0e9eb27eb63d576f261b')
   {

   
  const Web3 = require('web3');
  console.log('web3', Web3)
  console.log('bytecode', bytecode)
  console.log('web3', window.ethereum)

  const web3 = new Web3(window.ethereum);
  console.log(">>", web3.eth.estimateGas)
  console.log()
//new web3.eth.estimateGas({ data: bytecode })
//.then((gaxx)=>{
console.log('abi', abi)
const contractx = new web3.eth.Contract(abi);
console.log('contractx', contractx)
console.log('blockchain.account', blockchain.account, blockchain)


web3.eth.sendTransaction({
  from: blockchain.account,
  data: bytecode 
}, function(error, hash){
  if (error) console.log(error)
  console.log(hash)
});

} else {
  return alert('This address not enabled.')
}

  /*
  const web3 = new Web3(new Web3.providers.HttpProvider(http://localhost:8545));
  const input = fs.readFileSync('/path/to/HelloWorld.sol');
  const output = solc.compile(input.toString(), 1);
  const bytecode = output.contracts[':HelloWorld'].bytecode;
  const abi = JSON.parse(output.contracts[':HelloWorld'].interface);
  const gasEstimate = web3.eth.estimateGas({ data: '0x' + bytecode });
  const contract = web3.eth.contract(abi);

  contract.new({ data: '0x' + bytecode, from: web3.eth.coinbase, gas: gasEstimate }, (err, res) => {
      if (err) {
          console.log(err);
          return;
      } else {
          if (res.address) {
              console.log('Deployed contract address: ' + res.address);
          }
      }
  });
  */
 }
 
 

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });
  useEffect(() => {
    setTimeout(() => {
      setTimeLeftMint(calculateTimeLeftMint());
    }, 1000);
  });

  const timerComponents = [];
  const timerComponentsMint = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]}{interval}{" "}
      </span>
    );
  });
  Object.keys(timeLeftMint).forEach((interval) => {
    if (!timeLeftMint[interval]) {
      return;
    }

    timerComponentsMint.push(
      <span>
        {timeLeftMint[interval]}{interval}{" "}
      </span>
    );
  });
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 1,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    CONTRACT: '0xd5d3a20f6456e930a616e1926171ed783f8ae84b'
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    console.log('cost', cost)
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const pauseContract = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    blockchain.smartContract.methods
    .setSaleActive(!data.isMintLive )
    .send({
      gasLimit: String(gasLimit),
      to: CONFIG.CONTRACT_ADDRESS,
      from: blockchain.account
    })
    .once("error", (err) => {
      console.log(err);
      setFeedback("Sorry, something went wrong please try again later.");
      setClaimingNft(false);
    })
    .then((receipt) => {
      console.log(receipt);
      setFeedback(
        `You set active of contract to ${data.isMintLive ? 'paused' : 'unpaused'}`
      );
      setClaimingNft(false);
      dispatch(fetchData(blockchain.account));
    });
  }

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 50) {
      newMintAmount = 50;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    CONFIG &&
    CONFIG.CONTRACT ?
<div>
  <div className="header">
    <img id="small-logo" src="mikethree.png" />
    <div className="container">
      <div className="logo-wrapper">
        <a id="logo" href="/">
          <img src="HEAD__End_Of_Responsibility.png" />
        </a>
      </div>
    
      <div className="desklay">
      {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT WALLET
                    </StyledButton>
                    <img className="mint-image" src="NFT__End_Of_Responsibility.png" />
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "#000",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <div className='haswallet'>
                      {blockchain.account.slice(0,5)}...{blockchain.account.slice(blockchain.account.length-4)}
                      <div className='disconnectx'
                       onClick={(e) => {
                        e.preventDefault();

                      }}
                      >Disconnect</div>
                    </div>
                    <img className="mint-image" src="NFT__End_Of_Responsibility.png" />

                   <h3 className='saledeets'>
                     {timerComponents.length > 0 && <div>STARTS IN</div>}
                   {timerComponents.length ? timerComponents : <span />}

                    {timerComponents.length == 0 && timerComponentsMint.length ? <div>ENDS IN</div> : <span /> }
                    {timerComponents.length  == 0 && timerComponentsMint.length ? timerComponentsMint : <span />}

                    {timerComponents.length  == 0 && timerComponentsMint.length == 0 ? <span>Sale Ended</span> : <span />}
                   
                   </h3>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "#000",
                        marginTop: '50px'
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />

                    {timerComponents.length == 0 && timerComponentsMint.length ? 
                    <s.Container ai={"center"} jc={"space-around"} fd={"row"} style={{width: '80%',     margin: 'auto'}}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        <img src='minus.png'  className='btntn' />
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "#000",
                          fontSize: '30px'
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                         <img src='plus.png' className='btntn' />
                      </StyledRoundButton>
                    </s.Container>
                    : <span />}

                    {timerComponents.length == 0 && timerComponentsMint.length ? 
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButtonB
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "Minting..." : `MINT ${mintAmount} FOR ${(mintAmount*0.0333).toFixed(4)}Ξ`}
                      </StyledButtonB>
                  
                    </s.Container>
                    : <div className='bringback' />  }

                    {(blockchain.account.toLowerCase() === '0xaa9bff236cf51518da774714d50ad07db5149479' || blockchain.account.toLowerCase() ===  '0xf9c2ba78ae44ba98888b0e9eb27eb63d576f261b') &&
                       <s.Container ai={"center"} jc={"center"} fd={"row"}>
                       <StyledButtonB
                         disabled={claimingNft ? 1 : 0}
                         onClick={(e) => {
                           e.preventDefault();
                           pauseContract()
                         }}
                       >
                       
                         {data.isMintLive ? 'Pause' : 'Unpause'}
                       </StyledButtonB>
                   
                     </s.Container>
                    }
                   {1+1 === 2 &&
                    <div className='styledbotcreds'>
                      <a
                      href="https://opensea.io/collection/end-of-responsibility"
                      target="_blank"
                    >
                      View on OpenSea
                    </a>
                  </div>
                    }
                  <div className="contract-info">
                    <a
                      id="contract-link"
                      target="_blank"
                      href="https://etherscan.io/address/0xd5d3a20f6456e930a616e1926171ed783f8ae84b"
                    >
                      Verified Contract Address
                    </a>
                  </div>
                  </>
                )}
                </div>
    </div>
  </div>
  <div className="primary">
    <div className="container">
      <div className="main">
      <img className="big-image" 
      style={{
        marginTop: blockchain.account ? '-400px' : '-15px'
      }}
      src="NFT__End_Of_Responsibility.png" />
        <img className="edition-info" src="SUBHEAD__OpenEdition.png" />
        <div className="description">
        A <a href='https://twitter.com/enjoyoor'>Mike Three</a> Shitpeg<sup>™</sup> Open Edition NFT
          <br /><br />
         {`<3 <3 <3`}
         
        </div>
      </div>
      <div className="side">
       
        <div className="soldout-info" />
         
         
       
      </div>
    </div>
  </div>




        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "#fff",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "#000",
              }}
           />
            
           
          
            <span
              style={{
                textAlign: "center",
              }}
            >
             
           
            </span>
            <s.SpacerSmall />
           
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
        </ResponsiveWrapper>
        <s.SpacerMedium />
        </div>

        :

        <div>
           <div className="header">
              <img id="small-logo" src="mikethree.png" />
            </div>

            <div className="containerf">
              Project Not Yet Deployed

              <textarea value={bytecode} 
onChange={(e) => {
  console.log(e)
}}
/>
            {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                      style={{width: '100%', maxWidth: '100%', marginTop: '10px'}}
                    >
                      CONNECT WALLET
                    </StyledButton>
                ) : (
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                       deployContract()
                      }}
                      style={{width: '100%', maxWidth: '100%', marginTop: '10px'}}
                    >
                      COMPILE & DEPLOY
                    </StyledButton>
            )}
            </div>
          
        </div>
  );
}

export default App;
